import commonConfig from '../common';

const appConfig = {
  appType: 'ATC',
  app: {
    config: {
      listingTypeConfig: {
        vehicleListingType: 'used',
      },
    },
  },
  ...commonConfig,
};

export default appConfig;
