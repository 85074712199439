import React, { Fragment } from 'react';

import { FdpqWidgetWrapper } from '@packages/fdpq-widget';
import { config, theme } from '@packages/fdpq-core/config/atc';
import { PAGE_NAME } from '@packages/fdpq-core/constants/app';
import PageHead from '../components/PageHead';
import Global from '../components/Global';

// Emotion supports different styling options, all of which are supported by gatsby-plugin-emotion out of the box

const IndexPage = () => (
  <Fragment>
    <PageHead />
    <Global />
    <FdpqWidgetWrapper
      initialData={{
        theme,
        config,
        navigation: {
          currentPage: PAGE_NAME.VEHICLE_SELECTOR,
        },
      }}
    />
  </Fragment>
);

export default IndexPage;
