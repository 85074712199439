import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from '../images/favicon.ico';
import config from '../../packages/fdpq-core/config/common';

const gtmCode = config.gtmCode;

const dataLayer = 'window.dataLayer = window.dataLayer || [];';
const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmCode}');`;

const PageHead = () => (
  <Helmet
    htmlAttributes={{ lang: 'en' }}
    style={[{ cssText: `.grecaptcha-badge[data-style="bottomright"] { visibility: hidden; }` }]}
  >
    <meta name="referrer" content="origin" />
    <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <meta name="googlebot" content="noindex,indexifembedded" />
    <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
    <script>{dataLayer}</script>
    <script>{gtmScript}</script>
  </Helmet>
);

export default PageHead;
